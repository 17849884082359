<template>
    <div>
        <div class="container">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-pie-chart"></i> 数据分析
                </el-breadcrumb-item>
            </el-breadcrumb>

            <div style="display:flex;">
                <el-form ref="form" :model="curForm" label-width="120px" label-position="left" style="margin-top:20px;display:flex;justify-content:center;">
                    <el-form-item label="投放周期" >    
                        <el-date-picker                                
                                    v-model="curForm.dateArray"
                                    style="width:322px;"
                                    type="daterange"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :clearable="false"
                                    :shortcuts="shortcuts"
                                    @change ="handleDateChange"
                                    >
                        </el-date-picker>
                    </el-form-item>  
                </el-form>
            </div>

            <div style="display:flex;flex-direction:column;">
                <div>
                    <!-- <schart class="schart" width="100%" canvasId="monthChart" :options="monthOptions"></schart> -->
                    <line-chart ref="monthChart" :obj-name="'monthChart'" @onchange="onchange"/>   
                </div>

                <div style="display:flex;">
                    <div>统计周期：{{staticLabel}}</div>
                    <div v-if="staticLabel.length<8" style="margin-left:10px;cursor:pointer"><i class="el-icon-close" @click="onEmpty"></i></div>
                </div>

                <!-- <div style="background-color: rgb(231, 230, 227);">   -->
                    <div style="display:flex;justify-content:space-between;flex-wrap:wrap;margin-top:20px;">
                        <!-- <div class="schart-box">
                            <schart class="schart"   canvasId="areaChart" :options="areaOptions"></schart>
                        </div>                    
                        <div class="schart-box">                
                            <schart class="schart"  canvasId="productChart" :options="productOptions"></schart>
                        </div>
                        <div class="schart-box">                
                            <schart class="schart"  canvasId="cateChart" :options="cateOptions"></schart>
                        </div> -->
                        <div class="chart" style="width:45%;margin-top: 6px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <pie-chart ref="areaChart" :obj-name="'areaChart'" @onchange="onchange"/>  
                            
                        </div>
                        <el-button v-if="!isCityParent" style="margin-left: -80px;margin-top: 500px;" type="text" @click="returnParent(1)">返回</el-button>
                        <div class="chart" style="width:45%;margin-top: 6px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <pie-chart ref="productChart" :obj-name="'productChart'" @onchange="onchange"/>   
                        </div>
                        

                    
                    </div>
                    <div style="display:flex;justify-content:center ;flex-wrap:wrap;margin-top:130px;">
                        <div class="chart" style="width:50%;margin-top: 6px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <pie-chart ref="categoryChart" :obj-name="'categoryChart'" @onchange="onchange"/>   
                        </div>
                        <el-button v-if="!isCateParent" style="margin-top: 500px;" type="text" @click="returnParent(2)">返回</el-button>
                    </div>
                <!-- </div> -->

            </div>
            <!-- <el-dialog :title="title" v-model="popFormVisible" width="40%" @open="open()"
                                        :modal-append-to-body='false'
                                        :close-on-click-modal='false'
                                        v-if="popFormVisible"
                                        append-to-body>
                    <div style="display:flex;justify-content:center ;flex-wrap:wrap;margin-top:20px;">
                        <div class="chart" style="width:50%;margin-top: 6px;background-color:#fff;border-radius:15px;height:500px;margin-left:20px;">                       
                            <pie-chart ref="popChart" :obj-name="'popChart'" @onchange="onchange"/>   
                        </div>
                    </div>
                    <div class="button-footer;" style="display:flex;justify-content:flex-end;">                      
                        <span  class="dialog-footer" >
                            <el-button @click="popFormVisible=false">关闭</el-button>
                            
                        </span>                    
                    </div>
                </el-dialog> -->
        </div>
    </div>
</template>

<script>
import {getLatestMonthDateArrayByDate,getMonthDateArrayByPrePost,getMonthDateArrayByPrePostSafari} from "@/utils/time";
import {getToken} from "@/utils/auth";
import { mapGetters } from "vuex";
import PieChart from "@/components/PieChart";
import LineChart from "@/components/LineChart";
import {createPieData} from "@/utils/data-generator";

import Schart from 'vue-schart';
export default {
    name: 'basecharts',
    components: {
        Schart,PieChart,LineChart
    },
    data() {
        return {
            curForm: {    
                // dateArray:getLatestMonthDateArrayByDate({date: new Date(), delayNum: 6, formatValue: 'yyyy-M'}),
                dateArray:getMonthDateArrayByPrePostSafari({date: new Date(), preDelayNum: 6, postDelayNum: 6, formatValue: 'yyyy-M'}),
            },
            monthOptions: {},
            areaOptions: {},
            cateOptions: {},
            productOptions: {},
            curLabel:'',
            staticLabel:'',
            // popData:[],//点击后弹出窗口的数据
            // popFormVisible:false,
            // title:'',
            isCityParent:true,
            isCateParent:true,
        };
    },

    computed: {
        ...mapGetters(["Analysis_ByMonth","Analysis_Area","Analysis_Product","Analysis_Category","Analysis_CityStation","Analysis_CateBrand"])
    },

    async mounted() {
        // console.log('ddd')
        this.drawAllEchart();
    },

    methods:{
        handleDateChange(){
            this.drawAllEchart();
        },

        async onchange(curLabel){
            // console.log('curLabel',curLabel);
            this.title = curLabel.value;
            var chartTitle="";
            var tmpData=[];
            if (curLabel.objName=="areaChart" && this.isCityParent){
                chartTitle = this.title+' 车站贡献分布';
                tmpData = this.Analysis_CityStation.filter(data => data.city==curLabel.value);   
                var obj=[];
                tmpData.forEach(ele => {
                    obj.push({value:ele.value,name:ele.label});
                });
                // this.popFormVisible=true;  
                // console.log('this.$resf',this.$resf)
                this.$refs.areaChart.initChart(chartTitle,'单位：万元','销售额(万元)',obj)
                
                this.isCityParent=false;
            }else if (curLabel.objName=="categoryChart" && this.isCateParent){
                chartTitle = this.title+' 品牌贡献分布';
                tmpData = this.Analysis_CateBrand.filter(data => data.cateName==curLabel.value);
                var obj=[];
                tmpData.forEach(ele => {
                    obj.push({value:ele.value,name:ele.label});
                });
                // this.popFormVisible=true;  
                // console.log('this.$resf',this.$resf)
                this.$refs.categoryChart.initChart(chartTitle,'单位：万元','销售额(万元)',obj)
                
                this.isCateParent=false;
            }
            
                // console.log(tmpData)
                
            // this.curLabel = curLabel;
            // this.staticLabel = curLabel;
            // this.onDrawSubTable();
        },

        returnParent(flag){
            console.log('click',flag)
            if (flag==1){
                this.$refs.areaChart.initChart('资源销售地区分布Top10城市','单位：万元','销售额(万元)',this.Analysis_Area);
                this.isCityParent=true;
            }
            if (flag==2){
                this.$refs.categoryChart.initChart('销售行业分析','单位：万元','销售额(万元)',this.Analysis_Category)
                this.isCateParent=true;
            }
        },

        onEmpty(){
            this.curLabel='';
            const [sYM, eYM] = this.curForm.dateArray;
            var sDate = new Date(sYM).toLocaleDateString();
            var eDate = new Date(eYM).toLocaleDateString();

            this.staticLabel = sDate+'至'+eDate;
            this.onDrawSubTable();
        },


        handleDateChange(){
            this.drawAllEchart();
        },

        async onDrawSubTable(){
            var sDate,eDate;
            if (this.curLabel==""){
                const [sYM, eYM] = this.curForm.dateArray;
                sDate = new Date(sYM).toLocaleDateString();
                eDate = new Date(eYM).toLocaleDateString();
            }else{
                sDate=this.curLabel+'-01';
                eDate='';
            }
            

            this.$store.dispatch('analysis/Analisys_Sub', {userId: getToken(),sDate,eDate}).then(res=>{
                this.$refs.areaChart.initChart('资源销售地区分布Top10城市','单位：万元','销售额(万元)',this.Analysis_Area)
                this.$refs.productChart.initChart('产品包销售分析','单位：万元','销售额(万元)',this.Analysis_Product)
                this.$refs.categoryChart.initChart('销售行业分析','单位：万元','销售额(万元)',this.Analysis_Category)
            });
        },

        async drawAllEchart(){
            const [sYM, eYM] = this.curForm.dateArray;
            var sDate = new Date(sYM).toLocaleDateString();
            var eDate = new Date(eYM).toLocaleDateString();
            this.curLabel = '';
            this.staticLabel = sDate+'至'+eDate
            console.log('aa',this.curForm.dateArray,sYM,eYM,sDate,eDate)

            await this.$store.dispatch('analysis/Analisys', {userId: getToken(),sDate,eDate}).then(res=>{
                // this.drawMonthEcharts();
                // this.drawAreaEcharts();                
                // this.drawCateEcharts();
                // this.drawProductEcharts();
                this.$refs.monthChart.initChart('资源销售月度分布','销售额',this.Analysis_ByMonth)
                this.onDrawSubTable();
                
            });
        },

        async drawMonthEcharts() {
           
            var echarts = require('echarts');
            let bar = echarts.init(document.getElementById('monthChart'))
            var labels = [];
            var data=[];
                this.Analysis_ByMonth.forEach(element => {
                    labels.push(element.ym);
                    data.push(element.costs);
                });               
                this.monthOptions={
                    type: 'line',
                    title: {
                        text: '广告费用月度趋势'
                    },
                    legend: {
                        position: 'left'
                    },
                    colorList: ['#F89616', '#5EA690', '#93C73E','#E2338B','#F48781','#FF9393'],
                    bgColor: '#fbfbfb',
                    labels: labels,
                    datasets: [
                        {
                            data:data
                        }
                    ]
                };
                bar.setOption(this.monthOptions)
            // }).then(()=>{ })
        },

        open(){
            // console.log('do open')
            this.$nextTick(() => {
            //  执行echarts方法
            
            })
        },
    }

};

</script>

<style scoped>
.schart-box {
    display: inline-block;
    margin: 20px;
    width: 20%;
    margin-left:150px;
}
.chart{
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
}

.schart {
    width: 98%;
    height: 320px;
}
.content-title {
    clear: both;
    font-weight: 400;
    line-height: 50px;
    margin: 10px 0;
    font-size: 22px;
    color: #1f2f3d;
}
</style>